
.wrapper{
    max-width: 314px;
    margin: auto;
    padding: 5px;
    height: 45px;
    background: #ECEEF2;
    border-radius: 22px;
    margin-bottom: 40px;
}
.wrapper nav{
  position: relative;
  display: flex;
  height: 35px;
  width: 314px;
}
.wrapper nav label{
  flex: 1;
  width: 100%;
  z-index: 1;
  cursor: pointer;
  align-self: center;
  text-align: center;

  position: relative;
  color: #1d1f20;
  text-decoration: none;
  transition: color 0.6s ease;

  font-weight: 600;
  font-size: 14px;
  line-height: 139.3%;
  letter-spacing: -0.035em;
  text-transform: capitalize;

}
.wrapper nav #home:checked ~ label.home ,
.wrapper nav #inbox:checked ~ label.inbox  {
  color: #fff;
}

.wrapper nav .tab{
  position: absolute;
  height: 35px;
  width: 50%;
  left: 0;
  bottom: 0;
  z-index: 0;
  background: #0A7EF9;
    border-radius: 17.5px;
  transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper nav #inbox:checked ~ .tab{
  left: 50%;
}
.wrapper nav input{
  display: none;
}


.hidden {
  display: none;
}


.shimmer {
  display: inline-block;
  color: white;
  
  background: #1d1f20 -webkit-gradient(linear, 100% 0, 0 0, from(#1d1f20), color-stop(0.1, rgb(146, 146, 146)), to(#1d1f20));
  
  background-position: -4rem top; /*50px*/
  background-repeat: no-repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-name: shimmer;
  animation-duration: 4.2s;
  animation-iteration-count: infinite;
  background-size: 4rem 100%; /*50px*/
  
}

@keyframes shimmer {
    0% {
        background-position: -4rem top; /*50px*/
    }

    70% {
        background-position: 12.5rem top; /*200px*/
    }

    100% {
        background-position: 12.5rem top; /*200px*/
    }
}

@keyframes blur {
    0% {
      backdrop-filter: blur(5px);
    }

    100% {
      backdrop-filter: blur(10px);
    }
}