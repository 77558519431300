

    .tns-slider>.tns-item {
        font-size: 40px;
        opacity:.7;
        transform: scale(.8);
        transform-origin:center left;
        padding: 10px 0;

        @media only screen and (max-width: 1200px) {
            font-size: 30px;
            transform: none;
            padding: 0;
          }
    }

    .tns-slide-active {
        opacity:.5;
        transform: scale(.8);
        text-align: left;

        @media only screen and (max-width: 1200px) {
            font-size: 30px;
            transform: none;
          }
        
        
        &:nth-of-type(1) {
            opacity: 1;
            transition: all .5s ease-in-out;
            transform: scale(1);
        }
    }

    .slider-frame {
        overflow: unset !important;
    }
    .tns-carousel {
        h1:nth-of-type(1) {
            opacity: 1;
            transition: all .5s ease-in-out;
        }

        h1 {
            padding: 5px 0;
        }
    }

    .slider-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        @media only screen and (max-width: 1200px) {
            display: block;
            text-align: center;
          }

        .main-text {
            width: 220px;
            font-size: 40px;

            @media only screen and (max-width: 1200px) {
                width: 100%;
                margin-bottom: 30px;
              }
        }
    }

    .slider___action {
        text-align: center;
        button {
            margin-right: 1px;
            background: #0a7ef9;
            color: white;
            font-size: 16px;
            padding: 10px 20px;
            height: auto;
        }
    }

    .react-select__multi-value__remove {
        padding-right: 0  !important;
        svg {
            fill: white !important;
        }
    }


    .disappear-animatedinput {
        animation: 0.3s linear 1s disappear forwards;
        border: none;
    color: white;
background: #0A7EF9;
border-radius: 25px;
    height: 50px;
    padding: 12px 31px 12px 33px;

    font-weight: 600;
font-size: 14px;
line-height: 139.3%;
/* identical to box height, or 20px */

letter-spacing: -0.035em;

color: #FFFFFF;

        margin: auto;
display: flex;
    align-items: center;
    width: 153px;
    padding: 0;
    justify-content: center;
    position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
    }

    .disappear-animatedinput:focus {
        outline: none;
    }

    .appear-animatedinput {
        width: 153px;
        position: relative;
        display: contents;
        opacity: 0;
        animation: 0.3s  appearing forwards;
    }

    @keyframes disappear {
        0% {
          width: 153px;
          opacity: 1;
          top: 0;
          left: 50%;
          transform: translateX(-50%);          
        }
        100% {
          width: 100%;
          opacity: 0;
          display: none;
          background: #171B351A;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

    @keyframes appearing {
        0% {
          width: 153px;
          opacity: 0;
          
        }
        100% {
          width: 100%;
          opacity: 1;  
        }
      }